<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
      name: "App",
      components: {},
  };
</script>

<style>
  .b4014e33-99de-4bff-a040-79cf4526fd82 {
      fill: #333;
      fill-rule: evenodd;
  }
</style>
<style>
  .ff452229-05c2-4ff9-b426-bfc8edf0f91c {
      fill: #333;
  }
</style>
<style>
  .a29fadad-87cd-45dd-b870-cd004368b1e6 {
      fill: #333;
      fill-rule: evenodd;
  }
</style>
<style>
  .af59355c-1891-45e0-9269-0f4994d98bb7 {
      fill-rule: evenodd;
  }
</style>
<style>
  /*Tombon*/
  .content .elementor-button {
      color: #fff !important;
      background-color: #7d674c !important;
  }
  .content .elementor-button:hover {
      background-color: #534432 !important;
  }

  /*Text H2*/
  .content h2 {
      font-family: "Analogue", Times, serif !important;
      color: #7d674c !important;
  }

  /*Tanggal Event*/
  .content-date h2 {
      font-family: "Analogue", Times, serif !important;
      color: #7d674c !important;
  }

  /*Text P*/
  .content p {
      color: #7d674c !important;
  }
</style>

<style>
  .af59355c-1891-45e0-9269-0f4994d98bb7 {
      fill-rule: evenodd;
  }
</style>
